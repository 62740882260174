import React from 'react'
import '../MenuCard/MenuCard.css'

export default function MenuCard({ name, description, price }) {
  return (
    <div className="MenuCard">
      <div className="MenuCardItemsTop">
        <h2>{name}</h2>
        <h3>{price}</h3>
      </div>
      <div className="MenuCardItemsBottom">
        <p>{description}</p>
      </div>
    </div>
  );
}
