import React from 'react'
import '../PopularDishesItems/PopularDishesItems.css'

export default function PopularDishesItems() {
  return (
    <div className="popularDishesItems">
      <div className="popularDishesItemsImage">
        <img src="/images/Items/laphing.png" alt="" />
      </div>
      <div className="popularDishesItemsDesc">
        <h2>Jhol Laphing</h2>
        <h3>$11.99</h3>
      </div>
    </div>
  )
}
