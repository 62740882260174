import React from 'react'
import '../Footer/Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone, faLocationDot } from "@fortawesome/free-solid-svg-icons";


export default function Footer() {
  return(
    <div className='footer'>
      <div className="footerLogo">
        <img src="/images/LogoWhite.png" alt="" />
      </div>
      {/* <div className="footerLink">
        <a href="#">About</a>
        <a href="#">Menu</a>
        <a href="#">Contact</a>
      </div> */}
      <div className="footerLocation">
        <div className="footerLocationSub address">
          <FontAwesomeIcon icon={faLocationDot} size="2x" className="FontIcon" style={{ color: "#f2d6b1" }}/>
          <a href="https://maps.app.goo.gl/a6dFscfvBzgYSfUa9">624 Huron St, London, ON</a>
        </div>
        <div className="footerLocationSub phoneNumber">
          <FontAwesomeIcon icon={faPhone} size="2x" className="FontIcon"/>
          <a href="#">+1 (548) 866 0360</a>
        </div>
        <div className="footerLocationSub email">
          <FontAwesomeIcon icon={faEnvelope} size="2x" className="FontIcon"/>
          <a href="#">mustangthakali624@gmail.com</a>
        </div>
      </div>
      <div className="footerIcon">
        <a href="#">
          <FontAwesomeIcon icon={faFacebook} size="2x" className="FontIcon" />
        </a>
        <a href="#">
          <FontAwesomeIcon icon={faInstagram} size="2x" className="FontIcon" />
        </a>
        <a href="#">
          <FontAwesomeIcon icon={faTiktok} size="2x" className="FontIcon" />
        </a>
      </div>
      <div className="footerBottom">
        <p>Enjoy the taste of the Himalayas</p>
      </div>
    </div>
  )
}
