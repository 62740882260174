import React from 'react'
import '../Popular/PopularDishes.css'
import PopularDishesItems from './PopularDishesItems/PopularDishesItems'

export default function PopularDishes() {
  return (
    <div className="popularDishes">
      <h1>Popular Dishes</h1>
      <div className="popularDishesItemsContainer">
        <PopularDishesItems/>
        <PopularDishesItems/>
      </div>
    </div>
  )
}
