import React from 'react'
import '../Home/Home.css'

function Home() {
  return (
    <div className='Home'>
      <div className="HomeImage1">
        <img src="/images/home1.png"/>
      </div>
      <div className="HomeImage2">
        <img src="/images/home.png"/>
      </div>
      <div className="HomeContent">
        <h1>Enjoy the taste of the HIMALAYAS!</h1>
        <p>Embark on a flavorful adventure 
with our traditional Nepali dishes, 
expertly crafted to bring the 
authentic taste of Nepal right to 
your plate at Mustang Thakali & Bar!</p>
        <button class="Button">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="front">View Menu</span>
        </button>
      </div>
      
    </div>
  )
}

export default Home