import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import NavBar from './components/NavBar/NavBar.jsx';
import Home from './components/Home/Home.jsx';
import About from './components/About/About.jsx';
import Menu from './components/Menu/Menu.jsx';
import Footer from './components/Footer/Footer.jsx';
import PopularDishes from './components/Popular/PopularDishes.jsx';


function App() {
  return (
    <div className="App">
      <NavBar/>
      <Home/>
      {/* <About/> */}
      <PopularDishes/>
      <Menu/>
      <Footer/>
    </div>
  );
}

export default App;
